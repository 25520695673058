import { redirect } from 'next/navigation'
import type {
  APIOutOfServiceResponse,
  APIResponse,
  AuthUser,
} from './../types/index'
import { API_RESPONSE_CODE } from '@/constants'
import { http } from '@/lib/http'

export const getAuthUserPath = '/fan/v1/me'

// ユーザー情報を取得する
export const getAuthUser = async () => {
  const res = await http<APIResponse<AuthUser>>(getAuthUserPath).catch(
    (err) => {
      // BAN
      if (err.code === API_RESPONSE_CODE.banUser.value) {
        redirect('/in-ban')
      }

      // メンテ中
      if (err.code === API_RESPONSE_CODE.maintenance.value) {
        redirect('/in-maintenance')
      }

      return {
        result: null,
      }
    },
  )
  return res.result
}

// 利用不可状態の詳細情報を取得する
// BAN or メンテナンス
export const getOutOfService = async () => {
  const res = await http<APIResponse<AuthUser> | APIOutOfServiceResponse>(
    getAuthUserPath,
  ).catch((err) => {
    return {
      code: err.code,
      message: err.message,
      errors: err.errors,
      result: err.result,
    } as APIOutOfServiceResponse
  })
  return res
}
