type DataType = {
  [key: string]:
    | string
    | number
    | boolean
    | Array<string | number | boolean>
    | undefined
}

export const http = async <T>(
  path: string,
  options?: {
    /**
     * リクエストメソッド
     * Default:
     * 'GET'
     * */
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
    /**
     * ヘッダー
     * Default:
     * headers = {
     *   'Content-Type': 'application/json',
     * }
     * */
    headers?: { [key: string]: string | undefined }
    /** 送信データ */
    body?: BodyInit
    /** URLクエリ文字 */
    query?: DataType
    credentials?: RequestCredentials
  },
): Promise<T> => {
  const {
    method = 'GET',
    body,
    query,
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials = 'include',
  } = options ?? {}

  const url = new URL(process.env.NEXT_PUBLIC_API_BASE_URL as string)

  url.pathname = path

  if (query) {
    // biome-ignore lint/complexity/noForEach: <explanation>
    Object.keys(query).forEach((k) => {
      const queryValue = query[k]
      if (Array.isArray(queryValue)) {
        // biome-ignore lint/complexity/noForEach: <explanation>
        queryValue.forEach((v) => {
          url.searchParams.append(`${k}[]`, String(v))
        })
      } else if (queryValue != null) {
        url.searchParams.append(k, String(queryValue))
      }
    })
  }

  const cookie = await getCookie()
  const tokenKey = 'XSRF-TOKEN'
  const cookies = cookie.split('; ')
  const rawCsrfToken =
    cookies.find((cookie) => cookie.startsWith(tokenKey)) || ''

  let csrfToken = ''
  if (rawCsrfToken) {
    csrfToken = rawCsrfToken.replace(`${tokenKey}=`, '')
  } else {
    // tokenがない場合は発行して改めて取得
    await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/sanctum/csrf-cookie`, {
      method: 'GET',
      headers: {
        'X-XSRF-TOKEN': '',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      } as unknown as HeadersInit,
      next: { revalidate: 0 },
      referrer: process.env.NEXT_PUBLIC_BASE_URL,
      credentials,
    })
    const newCookie = await getCookie()
    const newCookies = newCookie.split('; ')
    const newRawCsrfToken =
      newCookies.find((_) => _.startsWith('XSRF-TOKEN')) || ''
    csrfToken = newRawCsrfToken.replace(`${tokenKey}=`, '')
  }

  const res = await fetch(url.toString(), {
    method: method,
    body: body,
    headers: {
      'X-XSRF-TOKEN': decodeURIComponent(csrfToken),
      ...headers,
      Cookie: cookie,
    } as unknown as HeadersInit,
    next: { revalidate: 0 },
    referrer: process.env.NEXT_PUBLIC_BASE_URL,
    credentials,
  })

  const responseJson = await res.json()

  if (responseJson.code !== 0) {
    return Promise.reject(responseJson)
  }

  return responseJson
}

// Cookieの取得
const getCookie = async () => {
  const isClient = typeof window !== 'undefined'
  if (isClient) {
    return document.cookie
  }
  const { cookies } = await import('next/headers')
  return cookies()
    .getAll()
    .map((cookie) => `${cookie.name}=${cookie.value}`)
    .join('; ') //document.cookieで返ってくる形式に合わせる
}
